import React from 'react';
import { RightCircleFilled } from '@ant-design/icons';

const SingleListElement = ({ textConent, pageId }) => {
  const handleClick = () => {
    const element = document.getElementById(pageId);
    if (element) element.scrollIntoView();
  };

  return (
    <li onClick={() => handleClick()}>
      <RightCircleFilled />
      {textConent}
    </li>
  );
};

export default SingleListElement;
