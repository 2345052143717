import React from 'react';
import SingleListElement from './SingleListElement';

const TitleListContainer = ({ contents }) => {
  return (
    <div className="default-info">
      <ul>
        {contents.map(({ caption, id }) => (
          <SingleListElement key={id} textConent={caption} pageId={id} />
        ))}
      </ul>
    </div>
  );
};

export default TitleListContainer;
