import React from 'react';
import TitleListContainer from './TitleListContainer';
import TitledContainer from './TitledContainer';
import { eachTabConstantTitles } from '../../../utils/Constants';
const {
  OVERVIEW_SCIENCE_BASED_TARGETS,
  LINK_PULSE_CHECK_SURVEY,
  SBTI_FAQS,
  SUSTAINABILITY_CONTACTS
} = eachTabConstantTitles;

const pageIndex = [
  {
    caption: 'WHAT ARE SCIENCE BASED TARGETS (SBT)?',
    id: OVERVIEW_SCIENCE_BASED_TARGETS
  },
  {
    caption: 'SBTi CLIMATE COMMITMENT SURVEY',
    id: LINK_PULSE_CHECK_SURVEY
  },
  {
    caption: 'SCIENCE BASED TARGET INITIATIVE (SBTi) FAQs',
    id: SBTI_FAQS
  },
  {
    caption: 'KEY SYSCO SUSTAINABILITY CONTACTS',
    id: SUSTAINABILITY_CONTACTS
  }
];

const contacts = [
  {
    img: '/static/media/tammy.0c09dead.png',
    name: 'Tammy McElroy',
    role: 'Senior Director',
    dept: 'Sustainability, Sysco'
  },
  {
    img: '/static/media/megan.ce2ddff2.png',
    name: 'Megan Stoffer',
    role: 'Senior Manger',
    dept: 'Sustainability, Sysco'
  }
];

const Sbti = () => {
  return (
    <div className="section-tab-wrapper">
      <div className="default-content info-sbti">
        <TitleListContainer contents={pageIndex} />
      </div>
      <TitledContainer title="WHAT ARE SCIENCE BASED TARGETS (SBT)?" pageId={OVERVIEW_SCIENCE_BASED_TARGETS} />
      <div className="title-section-content">
        <span>
          <b>Science based targets (SBT)</b> are a clearly-defined pathway for{' '}
          <b>companies to reduce greenhouse gas (GHG) emissions</b> Additional resources on SBT can be found in the
          below links:
        </span>
        <br></br>
        <br></br>
        <ul className="link-list">
          <li>
            SBTi: What are Scope 1, 2, and 3 emissions? (
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://sciencebasedtargets.org/faqs#what-are-the-emissions-scopes-which-scopes-do-targets-have-to-cover"
            >
              link
            </a>
            )
          </li>
          <li>
            SBTi: How it works (
            <a target="_blank" rel="noopener noreferrer" href="https://sciencebasedtargets.org/how-it-works">
              link
            </a>
            )
          </li>
          <li>
            SBTi: Resources (
            <a target="_blank" rel="noopener noreferrer" href="https://sciencebasedtargets.org/resources/">
              link
            </a>
            )
          </li>
          <li>
            SBTi: Set a Target (
            <a target="_blank" rel="noopener noreferrer" href="https://sciencebasedtargets.org/set-a-target">
              link
            </a>
            )
          </li>
        </ul>
      </div>
      <TitledContainer title="SBTi CLIMATE COMMITMENT SURVEY" pageId={LINK_PULSE_CHECK_SURVEY} />
      <div className="title-section-content">
        <p>
          A majority of Sysco's carbon footprint comes from the products we source (factored into our Scope 3 emissions
          target), we are actively engaging with our supplier partners to better understand their carbon reduction
          strategies.
        </p>
        <p>
          <b>
            If you or a colleague have not done so already, please complete this short survey to provide information on
            your current sustainability goals and commitments.
          </b>{' '}
          This should take less than 5 minutes to complete.
        </p>
        <p>
          <a rel="noopener noreferrer" href="/suite/supplier-climate-commitment">
            SBTi Survey
          </a>
        </p>
        <p>
          We will use your inputs to more effectively target ways to reduce our carbon footprint and partner with all of
          our suppliers on this incredibly important journey. If you believe you are not the right person in your
          organization to fill out this survey, please share this link with the appropriate person within your
          organization that has knowledge of your sustainability goals or future planned commitments.
        </p>
      </div>
      <TitledContainer title="SCIENCE BASED TARGET INITIATIVE (SBTi) FAQs" pageId={SBTI_FAQS} />
      <div className="title-section-content">
        <p>
          <b className="q">What if I've already set targets, they're just not science-based targets?</b>
        </p>
        <p>
          While already having targets in place is a great start, we are asking suppliers to commit to science-based
          targets specifically to ensure all our efforts are being measured and working towards the same goal.
        </p>
        <p>
          <b className="q">
            What happens if I am not committed? Will you stop doing business with my company or will we lose volume?
          </b>
        </p>
        <p>
          We are asking suppliers to share their plans because Sysco agreed to do so when we committed to SBTi. Although
          we hope you and other suppliers also commit to set science-based targets,{' '}
          <b>we are not requiring suppliers to do so at this time.</b>
        </p>
        <p>
          <b className="q">How do I set a target and how long does it take?</b>
        </p>
        <p>
          Companies first sign a commitment letter, after which they have 24 months to create an emissions baseline and
          develop and submit their science-based reduction targets. Once validated by SBTi, companies can communicate
          their target.
        </p>
        <p>
          <b className="q">Who else has done this already? Where can I learn more?</b>
        </p>
        <p>
          Over 4,000 companies globally have committed and set Science Based Targets, including Tyson, McCain, Walmart,
          Target, Disney, etc. You can learn more at the{' '}
          <a target="_blank" rel="noopener noreferrer" href="https://sciencebasedtargets.org/how-it-works">
            SBTi website
          </a>
          .
        </p>
        <p>
          <b className="q">What if I don't know how to commit?</b>
        </p>
        <p>
          The SBTi website provides guidance on how to set targets and commit to SBTi. You can refer to{' '}
          <a target="_blank" rel="noopener noreferrer" href="https://sciencebasedtargets.org/how-it-works">
            SBTi website
          </a>{' '}
          for additional guidance.
        </p>
        <p>
          <b className="q">What is the timeline for making SBTi commitments?</b>
        </p>
        <p>
          We are not recommending any deadlines to suppliers for making their SBTi commitments. However, we appreciate
          suppliers proactively working towards making SBTi commitments.
        </p>
        <p>
          <b className="q">What support will Sysco provide to help me commit, set targets, and/or reduce emissions?</b>
        </p>
        <p>
          Sysco is currently building resources and partnerships to help support our suppliers along this journey. We
          welcome feedback from you on the support you need, so please let us know if you have a specific need or
          requirement in mind.
        </p>
        <p>
          <b className="q">Will Sysco help me develop a baseline or set goals?</b>
        </p>
        <p>
          We plan to share additional resources and provide broad guidance for suppliers on how to develop emissions
          baselines and set targets.
        </p>
        <p>
          <b className="q">Who do I reach out to if I have more questions?</b>
        </p>
        <p>
          Please email{' '}
          <a target="_blank" rel="noopener noreferrer" href="mailto:sustainability@sysco.com">
            Sustainability@Sysco.com
          </a>{' '}
          with any further questions related to Sysco's Scope 3 emissions target
        </p>
      </div>
      <TitledContainer title="SYSCO SCOPE 3 TEAM CONTACT INFORMATION" pageId={SUSTAINABILITY_CONTACTS} />
      <div className="title-section-content">
        <div className="title-section-content">
          Email to:{' '}
          <a target="_blank" rel="noopener noreferrer" href="mailto:sustainability@sysco.com">
            Sustainability@Sysco.com
          </a>
        </div>
        <div className="contacts">
          {contacts.map(contact => (
            <div className="contact">
              <div className="pic" style={{ backgroundImage: `url(${contact.img})` }} />
              <div className="name">{contact.name}</div>
              <div className="role">{contact.role}</div>
              <div className="dept">{contact.dept}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Sbti;
