import React from 'react';
import TitledContainer from './TitledContainer';
import TitleListContainer from './TitleListContainer';

const PRESS_RELEASE = 'PRESS-RELEASE';
const CLIMATE_GOAL = 'CLIMATE-GOAL';
const CLIMATE_GOAL_VIDEO = 'CLIMATE-GOAL-VIDEO';

const pageIndex = [
  {
    caption: 'PRESS RELEASE',
    id: PRESS_RELEASE
  },
  {
    caption: 'CLIMATE GOAL ONE PAGER',
    id: CLIMATE_GOAL
  },
  {
    caption: 'CLIMATE GOAL VIDEO',
    id: CLIMATE_GOAL_VIDEO
  }
];

const Other = () => {
  return (
    <div className="section-tab-wrapper">
      <div className="default-content info-other">
        <TitleListContainer contents={pageIndex} />
      </div>
      <TitledContainer title="PRESS RELEASE" pageId={PRESS_RELEASE} />
      <div className="title-section-content">
        <p>
          Link:{' '}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://investors.sysco.com/annual-reports-and-sec-filings/news-releases/2021/11-22-2021-130429357"
          >
            Read Press Release
          </a>
        </p>
      </div>
      <TitledContainer title="CLIMATE GOAL ONE PAGER" pageId={CLIMATE_GOAL} />
      <div className="title-section-content">
        <p>
          Link:{' '}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://sysco.com/dam/jcr:4f5dc6a4-eb24-461a-a5f8-a155a11ec344/CSR%20-%20Climate%20Goals%20Flyer%202761399%20Corp%20042022_FINAL%20(1).pdf"
          >
            Read Climate Goal One-Page Overview
          </a>
        </p>
      </div>
      <TitledContainer title="CLIMATE GOAL VIDEO" pageId={CLIMATE_GOAL_VIDEO} />
      <div className="title-section-content">
        <p>
          Link:{' '}
          <a target="_blank" rel="noopener noreferrer" href="https://www.youtube.com/watch?v=XoGGuPX2e0I">
            Watch Climate Goal Video
          </a>
        </p>
      </div>
      <div style={{ marginBottom: '500px' }}></div>
    </div>
  );
};

export default Other;
