import React from 'react';
import SingleListElement from './SingleListElement';
import TitledContainer from './TitledContainer';
import { eachTabConstantTitles } from '../../../utils/Constants';
const { CLIMATE_CHANGE_COMMITMENT, SUSTAINABILITY_REPORT_HIGHLIGHT, RESPONSIBILITY_GOALS } = eachTabConstantTitles;

const Home = () => {
  const pageIndex = [
    {
      caption: "SYSCO'S GHG REDUCTION COMMITMENTS",
      id: CLIMATE_CHANGE_COMMITMENT
    },
    {
      caption: '2022 SUSTAINABILITY REPORT HIGHLIGHTS',
      id: SUSTAINABILITY_REPORT_HIGHLIGHT
    },
    {
      caption: '2025 RESPONSIBILITY GOALS',
      id: RESPONSIBILITY_GOALS
    }
  ];

  return (
    <div className="section-tab-wrapper">
      <div className="default-content info-home">
        <div className="default-info">
          <ul>
            {pageIndex.map(({ caption, id }) => (
              <SingleListElement key={id} textConent={caption} pageId={id} />
            ))}
          </ul>
        </div>
      </div>
      <div className="infomation-wrapper">
        <TitledContainer title="SYSCO'S GHG REDUCTION COMMITMENTS" pageId={CLIMATE_CHANGE_COMMITMENT} />
        <div className="title-section-content">
          <p>
            Agriculture and food production is a major contributor to global emissions of greenhouse gases (GHGs).{' '}
            <b>22% of global annual greenhouse gas emissions come from agriculture, forestry and other land use.</b>
          </p>
          <p>
            Moreover, agriculture is a major contributor to global emissions of GHGs and{' '}
            <b>agricultural production is expected to double by 2050</b> to meet the demand of the world's growing
            population. Prompt action from agriculture and food companies will be instrumental in helping to curb global
            GHG emissions and climate change.
          </p>
          <p>
            Sysco is committed to advancing sustainability by - Caring for <b>people</b>, sourcing <b>products</b>{' '}
            responsibly, and protecting the <b>planet</b>. As part of our planet commitments, we are working to reduce
            our carbon footprint by implementing science-based targets.
          </p>
          <p>
            Sysco emitted 72 million metric tons (mt) of GHG emissions which is our baseline CY2019 carbon footprint.{' '}
            <b>98% of these GHG emissions are generated in our supply chain activities</b>, which are our Scope 3
            emissions. The remaining 2% of the GHG emissions are Scope 1 and 2 emissions where Sysco has direct control.
          </p>
          <p>
            Sysco has committed to <b>reduce our Scope 1 and 2 emissions by 27.5% by 2030</b> from a CY2019 base year;
            However, these constitute only 2% of our GHG footprint and reducing our Scope 3 footprint will create a
            larger impact.
          </p>
          <p>
            <b>90% of our Scope 3 emissions come from purchased goods and services</b> which make partnering with our
            suppliers instrumental in achieving our targets. Sysco has committed to work with suppliers representing 67%
            of Scope 3 emissions to set science-based targets by 2026.
          </p>
          <p>Suppliers can refer to the SBTi Scope 3 section of the portal for additional information and resources.</p>
        </div>
        <TitledContainer title="2022 SUSTAINABILITY REPORT HIGHLIGHTS" pageId={SUSTAINABILITY_REPORT_HIGHLIGHT} />
        <div>
          <p>
            Sysco's latest Sustainability Report unifies our new Purpose and the company's enduring commitment to caring
            for people, sourcing products responsibly and respecting the planet.
          </p>
          <p>For more information refer to the Sysco 2022 Sustainability Report</p>
          <p>
            (&nbsp;
            <a
              className="hyper-link"
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.sysco.com/dam/Sysco/About/Corporate-Social-Responsibility/2022-Sustainability-Report.pdf"
            >
              English
            </a>
            {/* &nbsp;|&nbsp;
            <a
              className="hyper-link"
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.sysco.com/dam/jcr:0e1b50d8-b319-4fec-81af-6dafb537e4a7/Sysco_2021_CSR_v23_FR-CA.pdf"
            >
              Francais du Canada
            </a>
            &nbsp;|&nbsp;
            <a
              className="hyper-link"
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.sysco.com/dam/jcr:3799a79f-f735-4ec0-9a30-c886b47dbb12/Sysco_2021_CSR_v23_ES-LA.pdf"
            >
              Español Latino Americano
            </a> */}
            &nbsp;)
          </p>
        </div>
        <TitledContainer title="2025 RESPONSIBILITY GOALS" pageId={RESPONSIBILITY_GOALS} />
        <div>
          <p>
            Sysco's 2025 Responsibility Goals are aligned to three focus areas: <b>People, Products, Planet</b>
          </p>
          <p>Links to Pages with additional information:</p>
          <ul className="link-list">
            <li>
              People (
              <a target="_blank" rel="noopener noreferrer" href="https://www.sysco.com/Sustainability/People.html">
                link
              </a>
              )
            </li>
            <li>
              Products (
              <a target="_blank" rel="noopener noreferrer" href="https://www.sysco.com/Sustainability/Products.html">
                link
              </a>
              )
            </li>
            <li>
              Planet (
              <a target="_blank" rel="noopener noreferrer" href="https://www.sysco.com/Sustainability/Planet.html">
                link
              </a>
              )
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Home;
