export const tabPanes = [
  { tab: 'Home', key: 1 },
  { tab: 'Climate Target - FAQs', key: 2 },
  { tab: 'Other Information', key: 3 }
  // { tab: 'Sustainability Offering Program', key: 4, isDisabled: true }
];

export const eachTabConstantTitles = {
  CLIMATE_CHANGE_COMMITMENT: 'climate_change_commitment',
  SUSTAINABILITY_REPORT_HIGHLIGHT: 'sustainability_report_highlights',
  RESPONSIBILITY_GOALS: '2025_responsibility_goals',
  OVERVIEW_SCIENCE_BASED_TARGETS: 'overview_science_based_targets',
  LINK_PULSE_CHECK_SURVEY: 'link_pulse_check_survey',
  SBTI_FAQS: 'sbti_faqs',
  SUSTAINABILITY_CONTACTS: 'sustainability_contacts',
  PREVIOUS_COMMUNICATION: 'previous_communication',
  MESSAGES_FROM_LEADERSHIP: 'messages_FROM_LEADERSHIP'
};
