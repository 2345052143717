import initialState from 'store/initialState';

import { TABS } from 'actions/actionTypes';

const sustainability = (state = initialState.sustainability, action) => {
  switch (action.type) {
    case TABS.REQUEST: {
      const { tabId } = action;
      return {
        ...state,
        activeTab: tabId
      };
    }
    default: {
      return {
        ...state
      };
    }
  }
};

export default sustainability;
