import React from 'react';
import Home from './Home';
import Sbti from './Sbti';
import Other from './Other';

const TabContent = ({ tabKey }) => {
  switch (tabKey) {
    case 1: {
      return <Home />;
    }
    case 2: {
      return <Sbti />;
    }
    case 3: {
      return <Other />;
    }
    default: {
      return null;
    }
  }
};

export default TabContent;
