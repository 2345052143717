import React from 'react';

const TitledContainer = ({ title, pageId }) => {
  return (
    <div id={pageId} className="section-title">
      {title}
    </div>
  );
};

export default TitledContainer;
