import React, { useCallback } from 'react';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { Tabs } from 'antd';
import 'antd/dist/antd.css';
import { tabPanes } from '../../utils/Constants';
import TabContent from './TabContent';
import { handleTabChange } from '../../actions';

const { TabPane: Pane } = Tabs;

const TabPane = () => {
  const dispatch = useDispatch();
  const activatedTab = useSelector(state => _.get(state.sustainability, 'activeTab', 1));

  const handleOnOkButtonClick = useCallback(
    tabId => {
      dispatch(handleTabChange(tabId));
    },
    [dispatch]
  );

  return (
    <div className="main-tab-wrapper">
      <Tabs
        defaultActiveKey={activatedTab}
        onTabClick={e => handleOnOkButtonClick(e)}
        animated={false}
      >
        {tabPanes.map(({ tab, key, isDisabled }) => {
          return (
            <Pane tab={tab} key={key} disabled={isDisabled}>
                <TabContent tabKey={key} />
            </Pane>
          );
        })}
      </Tabs>
    </div>
  );
};

export default TabPane;
