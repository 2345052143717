import React, { useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import _ from 'lodash';

import Home from './pages';
import AppLoader from 'components/AppLoader';
import * as userActions from 'actions';
import HeaderTab from './components/Templates/HeaderTab';

import AppLogo from './styles/images/app-logos/item-mgmt.svg';
import styles from './styles/main.scss';

const setAppLogo = () => {
  if (document.getElementById('app-logo')) {
    document.getElementById('app-logo').src = AppLogo;
  }
};

const setAppHeaderToAppMode = () => {
  if (document.getElementById('app-bar')) {
    document.getElementById('app-bar').className = 'app-bar app-mode';
  }
};

function MainApp({ suvc, userId, userPermissions, actions, userFetching }) {
  setAppLogo();
  setAppHeaderToAppMode();

  useEffect(() => {
    styles.use();
    return () => {
      styles.unuse();
    };
  }, []);

  // useEffect(() => {
  //   actions.loadUserDetails(userId);
  // }, [actions, userId]);

  if (!userFetching && _.isEmpty(userPermissions)) {
    return (
      <div className="wrapper contacts-wrapper">
        <HeaderTab />
        <BrowserRouter>
          <Switch>
            <Route exact path="/suite/sustainability" component={Home} />
          </Switch>
        </BrowserRouter>
      </div>
    );
  } else {
    return <AppLoader show />;
  }
}

function mapStateToProps(state) {
  return {
    user: state.user.data,
    userPermissions: state.user.data.permissions,
    userFetching: state.user.fetching
  };
}

function mapDispatchToProps(dispatch) {
  return { actions: bindActionCreators(userActions, dispatch) };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MainApp);
